import { createBoxManifest } from './box-manifest'
import { createFieldsManifests } from './field-manifest'
import {
  ROLE_FORM,
  FIELDS_ROLES,
  ROLE_SUBMIT_BUTTON,
  ROLE_MESSAGE,
  ROLE_TITLE,
} from '../../../constants/roles'
import { createSubmitButtonManifest } from './submit-button-manifest'
import { createHiddenMessageManifest } from './hidden-message-manifest'
import { createTitleManifest } from './title-manifest'

const ALWAYS_HIDE_CONTROLLER = 'NEVER'

export const getAppManifest = () => {
  const fieldManifests = FIELDS_ROLES.reduce(
    (res, fieldRole) => ({
      ...res,
      [fieldRole]: createFieldsManifests()[fieldRole],
    }),
    {}
  )
  const submitButtonManifest = createSubmitButtonManifest()
  const hiddenMessageManifes = createHiddenMessageManifest()
  const titleManifest = createTitleManifest()
  const createManifest = isPremuim => ({
    visibility: ALWAYS_HIDE_CONTROLLER,
    connections: {
      [ROLE_FORM]: createBoxManifest(isPremuim),
      [ROLE_SUBMIT_BUTTON]: submitButtonManifest,
      [ROLE_MESSAGE]: hiddenMessageManifes,
      [ROLE_TITLE]: titleManifest,
      ...fieldManifests,
    },
  })

  return {
    controllersStageData: {
      singlePostController: {
        default: createManifest(false),
        premium: createManifest(true),
      },
    },
  }
}
