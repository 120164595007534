import { ComponentRef } from '../api-types'
import CoreApi from '../core-api'
import RemoteApi from '../../../panels/commons/remote-api'
import { DEFAULT_RESTRICTIONS, PremiumRestriction } from '../../../constants/premium'

export default class PremiumApi {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private experiments: any
  private remoteApi: RemoteApi

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  public async setPremiumState(controllerRefs?: ComponentRef[]) {
    const { restrictions } = await this.getPremiumRestrictions()
    if (restrictions.isTopPremium) {
      return
    }
    if (!controllerRefs) {
      const controllers: {
        controllerRef: ComponentRef
      }[] = await this.boundEditorSDK.controllers.listAllControllers()
      controllerRefs = controllers.map(({ controllerRef }) => controllerRef)
    }
    return this.boundEditorSDK.controllers.setState({ state: { premium: controllerRefs } })
  }

  public async getPremiumRestrictions(): Promise<{ restrictions: PremiumRestriction }> {
    return (
      (!(await this.coreApi.isTemplate()) && this.remoteApi.getPremiumRestrictions()) ||
      Promise.resolve({ restrictions: DEFAULT_RESTRICTIONS })
    )
  }
}
