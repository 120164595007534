import * as _ from 'lodash'
import * as textInput from './styles/textInput.json'
import * as datePicker from './styles/datePicker.json'
import * as dropdown from './styles/dropdown.json'
import * as textArea from './styles/textArea.json'
import * as radio from './styles/radio.json'
import * as button from './styles/button.json'
import * as box from './styles/box.json'
import * as theme01 from '../../../assets/components/theme01.json'
import * as theme02 from '../../../assets/components/theme02.json'
import * as theme03 from '../../../assets/components/theme03.json'
import * as theme04 from '../../../assets/components/theme04.json'
import * as theme05 from '../../../assets/components/theme05.json'
import * as theme06 from '../../../assets/components/theme06.json'
import { Theme } from '../../../constants/form-style'
import { innerText } from '../../../utils/utils'

const handlePropertiesAsStyle = properties => {
  const propertiesSource = _.mapValues(properties, 'theme')

  return comp => {
    return _.merge({}, comp, { style: { style: { properties, propertiesSource } } })
  }
}

const handleByType = {
  'wysiwyg.viewer.components.inputs.TextInput': handlePropertiesAsStyle(textInput),
  'wysiwyg.viewer.components.inputs.TextAreaInput': handlePropertiesAsStyle(textArea),
  'wysiwyg.viewer.components.inputs.DatePicker': handlePropertiesAsStyle(datePicker),
  'wysiwyg.viewer.components.inputs.ComboBoxInput': handlePropertiesAsStyle(dropdown),
  'wysiwyg.viewer.components.inputs.RadioGroup': handlePropertiesAsStyle(radio),
  'wysiwyg.viewer.components.SiteButton': handlePropertiesAsStyle(button),
  'mobile.core.components.Container': handlePropertiesAsStyle(box),
}

export const applyStyle = (comp, theme) => {
  if (theme) {
    const style = getThemeStyle(theme)[comp.role]
    if (!style) {
      return comp
    }
    if (!_.isString(style)) {
      return handlePropertiesAsStyle(style)(comp)
    }
    const text = comp.data.text
    const newText = _.replace(style, 'TITLE', innerText(text))
    return _.merge({}, comp, { data: { text: newText } })
  }
  const styleHandler = handleByType[comp.componentType]
  return styleHandler ? styleHandler(comp) : comp
}

export const applyProps = (comp, theme) => {
  if (!theme) {
    return comp
  }
  const props = getThemeProps(theme)[comp.role]
  if (!props) {
    return comp
  }
  return _.merge({}, comp, { props })
}

const THEMES_STYLES = {
  [Theme.THEME01]: theme01,
  [Theme.THEME02]: theme02,
  [Theme.THEME03]: theme03,
  [Theme.THEME04]: theme04,
  [Theme.THEME05]: theme05,
  [Theme.THEME06]: theme06,
}

export const getThemeStyle = (theme: Theme) => THEMES_STYLES[theme].style
export const getThemeProps = (theme: Theme) => THEMES_STYLES[theme].props
