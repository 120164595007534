import * as _ from 'lodash'
import { isWixEmployeeEmail } from '../../utils/utils'
import { ROLE_FORM, ROLE_MESSAGE, ROLE_SUBMIT_BUTTON, FIELDS } from '../../constants/roles'
import translations from './services/translations'
import {
  ComponentConnection,
  ComponentRef,
  CriticalComponentTypes,
  FormFieldDefinition,
} from './api-types'
import { SuccessActionTypes } from '../../constants/success-settings'
import RemoteApi from '../../panels/commons/remote-api'
import { EVENTS } from '../../constants/bi'
import { generateRuntimeCoreApi, undoable, withBi } from './utils'
import LayoutApi from './layout-panel/api'
import StyleApi from './style-panel/api'
import AddFormApi from './add-form/api'
import CollectionsApi from './collections/api'
import SettingsApi from './settings-panel/api'
import ManagePanelsApi from './manage-panels/api'
import FieldsApi from './fields/api'
import PremiumApi from './premium/api'

export default class CoreApi {
  private experiments: any
  private ravenInstance: any
  private remoteApi: RemoteApi
  private biLogger: any

  public layout: LayoutApi
  public style: StyleApi
  public addForm: AddFormApi
  public collectionsApi: CollectionsApi
  public settings: SettingsApi
  public managePanels: ManagePanelsApi
  public fields: FieldsApi
  public premium: PremiumApi

  constructor(
    private boundEditorSDK,
    private editorSDK,
    {
      apis: { collectionsApi, collectionsPermissionsApi, remoteApi },
      experiments,
      ravenInstance,
      biLogger,
    }
  ) {
    const helpers = { experiments, biLogger }
    this.collectionsApi = new CollectionsApi(
      boundEditorSDK,
      collectionsApi,
      collectionsPermissionsApi,
      helpers
    )
    this.remoteApi = remoteApi
    this.boundEditorSDK = boundEditorSDK
    this.editorSDK = editorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
    this.biLogger = biLogger

    this.layout = new LayoutApi(boundEditorSDK, this, helpers)
    this.style = new StyleApi(boundEditorSDK, this, helpers)
    this.addForm = new AddFormApi(boundEditorSDK, this, this.collectionsApi, helpers)
    this.settings = new SettingsApi(boundEditorSDK, editorSDK, this, remoteApi, helpers)
    this.managePanels = new ManagePanelsApi(boundEditorSDK, editorSDK, this, helpers)
    this.fields = new FieldsApi(boundEditorSDK, this, remoteApi, helpers)
    this.premium = new PremiumApi(boundEditorSDK, this, remoteApi, helpers)
  }

  public setBiLogger(biLogger) {
    this.biLogger = biLogger
  }

  public async connect(
    { connectionConfig, role },
    controllerRef: ComponentRef,
    connectToRef: ComponentRef
  ) {
    await this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig,
      isPrimary: true,
    })
    return { role, connectionConfig, connectToRef, controllerRef }
  }

  public async addComponentAndConnect(
    { data, connectionConfig, role },
    controllerRef: ComponentRef,
    containerRef: ComponentRef
  ) {
    const connectToRef = await this.boundEditorSDK.components.add({
      componentDefinition: data,
      pageRef: containerRef,
    })

    return this.connect(
      { connectionConfig, role },
      controllerRef,
      connectToRef
    )
  }

  public async getFormId(componentRef) {
    const formCompRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    return formCompRef.id
  }

  public async findConnectedComponent(
    controllerRef: ComponentRef,
    childRole
  ): Promise<ComponentRef> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const obj = await Promise.all(
      childComps.map(async child => {
        const { role } = await this.getComponentConnection(child)
        return role === childRole ? child : null
      })
    )
    return <ComponentRef | null>_.find(obj, x => !!x)
  }

  public async findComponentByRole(componentRef: ComponentRef, childRole) {
    const { controllerRef } = await this.getComponentConnection(componentRef)
    return this.findConnectedComponent(controllerRef, childRole)
  }

  public generateRuntimeApi() {
    return generateRuntimeCoreApi(
      this,
      {
        layout: this.layout,
        style: this.style,
        settings: this.settings,
        addForm: this.addForm,
        managePanels: this.managePanels,
        fields: this.fields,
        premium: this.premium,
      },
      this.ravenInstance
    )
  }

  public async getComponentConnection(componentRef: ComponentRef): Promise<ComponentConnection> {
    const connections = await this.boundEditorSDK.controllers.listConnections({
      componentRef,
    })
    return _.find(connections, ['isPrimary', true]) || {}
  }

  public async setComponentConnection(connectToRef: ComponentRef, connectionConfig) {
    const { controllerRef, role, config } = await this.getComponentConnection(connectToRef)
    return this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig: _.merge({}, config, connectionConfig),
      isPrimary: true,
    })
  }

  public async saveSite() {
    return this.boundEditorSDK.editor.save()
  }

  public async saveSiteIfUnsaved() {
    const isSiteSaved = await this.boundEditorSDK.info.isSiteSaved()
    return isSiteSaved || this.saveSite()
  }

  @undoable()
  public async createCollection(componentRef: ComponentRef, biData = {}): Promise<string> {
    const {
      config: { formName, preset },
    } = await this.getComponentConnection(componentRef)
    const collectionId = await this.collectionsApi.createCollection({ preset }, biData)
    await this.collectionsApi.addFieldsToCollection(
      collectionId,
      await this.fields.getFieldsSortByXY(componentRef),
      (fieldComponent, fieldKey) =>
        this.setComponentConnection(fieldComponent, { collectionFieldKey: fieldKey })
    )
    await this.setComponentConnection(componentRef, { collectionId })
    await this.editDraft(componentRef, formName, collectionId)
    if (this.experiments.enabled('specs.cx.FormBuilderSaveOnCreateCollection')) {
      await this.saveSite()
    }
    return collectionId
  }

  public async getOwnerEmailId() {
    const data = await this.remoteApi.getOwnerEmail().catch(() => null)
    return data ? (isWixEmployeeEmail(data.email) ? '' : data.emailId) : ''
  }

  public async editDraft(componentRef, formName, collectionId = null) {
    const connectedFields = await this.fields.getFieldsSortByXY(componentRef)
    const formData: any = {
      formId: componentRef.id,
      formName,
      fields: _.map(connectedFields, connectedField => ({
        fieldId: connectedField.componentRef.id,
        fieldName: connectedField.crmLabel,
      })),
    }

    if (collectionId) {
      formData.collectionId = collectionId
    }

    return this.remoteApi.editDraft(formData)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.manageFieldsPanel.DELETE_FIELD })
  public removeComponent(componentRef: ComponentRef, _biData = {}) {
    return this.removeComponentRef(componentRef)
  }

  public removeComponentRef(componentRef: ComponentRef) {
    return this.boundEditorSDK.components.remove({ componentRef })
  }

  public async getButtonLabel(componentRef: ComponentRef): Promise<string> {
    const { label } = await this.boundEditorSDK.components.data.get({ componentRef })
    return label
  }

  @undoable()
  public updateButtonLabel(componentRef: ComponentRef, buttonLabel) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { label: buttonLabel },
    })
  }

  public async isTemplate(): Promise<boolean> {
    return !(await this.boundEditorSDK.info.isSiteSaved())
  }

  public getMetaSiteId(): Promise<string> {
    return this.boundEditorSDK.info.getMetaSiteId()
  }

  public getEditorSessionId(): Promise<string> {
    return this.boundEditorSDK.info.getEditorSessionId()
  }

  public async isMobileMode(): Promise<boolean> {
    return (await this.boundEditorSDK.info.getEditorMode()) === 'mobile'
  }

  private async _isComponentExists(componentRef: ComponentRef) {
    try {
      const componentType = await this.boundEditorSDK.components.getType({ componentRef })
      return !!componentType
    } catch (ex) {
      return false
    }
  }

  public async handleDelete({ controllerRef, role }, historySnapshotId) {
    const sameRoleComp = await this.findConnectedComponent(controllerRef, role)
    if (sameRoleComp) {
      return
    }
    if (role === ROLE_FORM) {
      if (!(await this.isMobileMode())) {
        const connectedRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
          controllerRef,
        })
        await Promise.all(
          connectedRefs.map(async componentRef =>
            this.boundEditorSDK.components.remove({ componentRef })
          )
        )

        const isComponentExists = await this._isComponentExists(controllerRef)
        if (isComponentExists) {
          await this.boundEditorSDK.components.remove({ componentRef: controllerRef })
        }
        await this.boundEditorSDK.history.add({ label: 'History', historySnapshotId })
      }
    } else {
      const componentRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)
      if (componentRef) {
        if (role === ROLE_SUBMIT_BUTTON) {
          const {
            config: { preset },
          } = await this.getComponentConnection(componentRef)
          const esi = await this.getEditorSessionId()
          await this._deleteSubmissionButton(componentRef, {
            startBi: {
              form_comp_id: componentRef.id,
              template: preset,
              esi,
            },
          })
        } else if (role === ROLE_MESSAGE) {
          const {
            config: { successActionType },
          } = await this.getComponentConnection(componentRef)
          if (this._shouldOpenTooltipForDeletedSuccessMessage(successActionType, componentRef)) {
            await this._popRestoreNotification(componentRef, CriticalComponentTypes.HIDDEN_MESSAGE)
          }
        } else if (role === FIELDS.ROLE_FIELD_SUBSCRIBE) {
          // TODO: Do something when subscribe component deleted
        }
      }
      this.boundEditorSDK.selection.selectComponentByCompRef({ compsToSelect: [componentRef] })
    }
  }

  @withBi({ startEvid: EVENTS.EDITOR.DELETE_SUBMISSION_BUTTON })
  private async _deleteSubmissionButton(componentRef, _biData) {
    this._popRestoreNotification(componentRef, CriticalComponentTypes.SUBMIT_BUTTON)
  }

  private async _popRestoreNotification(
    componentRef: ComponentRef,
    deletedComponentType: CriticalComponentTypes
  ) {
    const isMobileMode = await this.isMobileMode()
    this.boundEditorSDK.editor
      .showNotification({
        title: translations.t(`quicktipDelete.title`),
        message: isMobileMode
          ? translations.t(`quicktipDelete.${deletedComponentType}.mobileText`)
          : translations.t(`quicktipDelete.${deletedComponentType}.text`),
        type: 'warning',
        link: {
          caption: isMobileMode
            ? ''
            : translations.t(`quicktipDelete.${deletedComponentType}.link`),
        },
      })
      .then(shouldRestore => {
        if (!shouldRestore) {
          return
        }
        switch (deletedComponentType) {
          case CriticalComponentTypes.SUBMIT_BUTTON:
            this.settings.addSubmitButton(componentRef)
            break
          case CriticalComponentTypes.HIDDEN_MESSAGE:
            this.settings.addHiddenMessage(componentRef)
            break
        }
      })
  }

  private _shouldOpenTooltipForDeletedSuccessMessage(
    successActionType: SuccessActionTypes,
    componentRef: ComponentRef
  ) {
    return successActionType !== SuccessActionTypes.LINK && componentRef
  }

  public async isCollectionExists(componentRef) {
    const {
      config: { collectionId },
    } = await this.getComponentConnection(componentRef)
    return this.collectionsApi.isCollectionExists(collectionId)
  }

  public async sendAllFormsData() {
    const controllers = await this.boundEditorSDK.controllers.listAllControllers()
    const collectionsById = await this.collectionsApi.getCollectionMapById()
    const forms = await Promise.all(
      _.map(controllers, async ({ controllerRef }) => {
        const formRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)
        if (!formRef) {
          return
        }
        const { config } = await this.getComponentConnection(formRef)
        const fields = await this.fields.getFieldsSortByXY(formRef)

        return {
          formId: formRef.id,
          formName: config.formName,
          collectionId: collectionsById[config.collectionId] && config.collectionId,
          fields: _.map(fields, field => {
            const fieldDef: FormFieldDefinition = {
              fieldId: field.componentRef.id,
              fieldName: field.crmLabel,
            }

            if (field.collectionFieldKey) {
              fieldDef.fieldCollectionKey = field.collectionFieldKey
            }

            return fieldDef
          }),
        }
      })
    )
    const formData = {
      forms: _.filter(forms, x => !!x),
    }
    return this.remoteApi.publishSite(formData)
  }

  public async isRegistrationForm(componentRef) {
    const formCompRef: ComponentRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formCompRef)
    return _.find(config.plugins, { id: 'registrationForm' })
  }

  public async reportBiFirstSave(oldSiteId) {
    const controllers: {
      controllerRef: ComponentRef
    }[] = await this.boundEditorSDK.controllers.listAllControllers()
    const formRef: ComponentRef = await this.findConnectedComponent(
      controllers[0].controllerRef,
      ROLE_FORM
    )
    const {
      config: { preset },
    } = await this.getComponentConnection(formRef)
    this.biLogger.log({
      evid: EVENTS.EDITOR.USER_SAVE_TEMPLATE_WITH_FORM,
      form_comp_id: formRef.id,
      template: preset,
      templateId: oldSiteId,
    })
  }

  public async createTag(tagName: string) {
    return this.remoteApi.createTag(tagName)
  }
}
