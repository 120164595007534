import * as _ from 'lodash'
import { ComponentRef, ColorPalette } from '../api-types'
import { isAnyField, undoable, withBi } from '../utils'
import CoreApi from '../core-api'
import { getStyleValues, isFormStyle, calcCommonStyle } from '../services/form-style-service'
import { getThemeStyle, getThemeProps } from '../preset/preset-styles'
import { FormAlphaStyle, FormStyle, Theme } from '../../../constants/form-style'
import { innerText } from '../../../utils/utils'
import { EVENTS } from '../../../constants/bi'

const paletteToMatrix = palette => {
  const result = [[], [], [], [], []]

  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < 5; j++) {
      result[i][j] = palette[`color_${i * 5 + j + 11}`]
    }
  }

  return result
}
export default class StyleApi {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private biLogger: any

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formStylePanel.CUSTOM_DESIGN_ACTION })
  public async updateFieldsStyle(
    componentRef: ComponentRef,
    {
      styleName,
      newStyleValue,
      commonStyles,
    }: { styleName: FormStyle | FormAlphaStyle; newStyleValue: string; commonStyles: object },
    _biData
  ) {
    let fields
    if (isFormStyle(styleName)) {
      fields = [{ componentRef }]
    } else {
      fields = await this.coreApi.fields.getFieldsSortByXY(componentRef, {
        allFieldsTypes: true,
      })
    }

    return Promise.all(
      fields.map(({ componentRef: fieldRef }) =>
        this._updateCompStyle(fieldRef, { styleName, newStyleValue, commonStyles })
      )
    )
  }

  public async getColorsPalette(): Promise<ColorPalette> {
    const palette = await this.boundEditorSDK.theme.colors.getAll()
    return { colorsPalette: paletteToMatrix(palette), colors: palette }
  }

  public openColorPicker(options, onColorChange) {
    return this.boundEditorSDK.editor.openColorPicker(options, onColorChange)
  }

  public getFontsOptions() {
    return this.boundEditorSDK.fonts.getFontsOptions()
  }

  public getThemedFonts() {
    return this.boundEditorSDK.theme.fonts.getMap()
  }

  public async getFieldsCommonStyles(componentRef: ComponentRef) {
    const {
      style: { properties } = { properties: {} },
    } = await this.boundEditorSDK.components.style.get({
      componentRef,
    })
    const form = { style: properties }

    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const childrenRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const children = await this.boundEditorSDK.components.get({
      componentRefs: childrenRefs,
      properties: ['style', 'connections'],
    })
    const fields = _.flatMap(
      children,
      ({ connections, style }) =>
        isAnyField(_.get(connections, '[0].role'))
          ? {
              style: _.get(style, 'style.properties'),
            }
          : []
    )

    return calcCommonStyle(form, fields)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formStylePanel.CUSTOM_DESIGN_ACTION })
  public async updateTheme(componentRef: ComponentRef, theme: Theme, _biData) {
    await this.coreApi.setComponentConnection(componentRef, { theme })
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const children = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const stylesByRole = getThemeStyle(theme)
    const propsByRole = getThemeProps(theme)

    return Promise.all(
      _.map(children, async (childRef: ComponentRef) => {
        const { role } = await this.coreApi.getComponentConnection(childRef)
        const style = stylesByRole[role]
        const props = propsByRole[role]
        if (props) {
          await this.boundEditorSDK.components.properties.update({
            componentRef: childRef,
            props,
          })
        }

        if (!style) {
          return
        }
        if (!_.isString(style)) {
          return this.boundEditorSDK.components.style.update({ componentRef: childRef, style })
        }

        const { text } = await this.boundEditorSDK.components.data.get({ componentRef: childRef })
        const newText = _.replace(style, 'TITLE', innerText(text))
        return this.boundEditorSDK.components.data.update({
          componentRef: childRef,
          data: { text: newText },
        })
      })
    )
  }

  private async _updateCompStyle(
    componentRef: ComponentRef,
    { styleName, newStyleValue, commonStyles }
  ) {
    const {
      style: { properties } = { properties: {} },
    } = await this.boundEditorSDK.components.style.get({
      componentRef,
    })
    return this.boundEditorSDK.components.style.update({
      componentRef,
      style: _.merge({}, properties, getStyleValues(styleName, newStyleValue, commonStyles)),
    })
  }
}
