import * as i18next from 'i18next'
import * as i18nextXHRBackend from 'i18next-xhr-backend'

const i18nextCore = (locale, baseUrl, cb) => {
  i18next.use(i18nextXHRBackend).init(
    {
      lng: locale,
      fallbackLng: 'en',
      keySeparator: '$',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${baseUrl}assets/locale/builder/messages_{{lng}}.json`,
        crossDomain: true,
      },
    },
    (err, t) => cb(err, t, i18next)
  )
}

export default function i18n({
  locale,
  baseUrl = '',
}): Promise<{ t: Function; i18nInstance: any }> {
  return new Promise((resolve, reject) => {
    i18nextCore(
      locale,
      baseUrl,
      (err, t, i18nInstance) => (err ? reject(err) : resolve({ t, i18nInstance }))
    )
  })
}
