import CoreApi from '../core-api'
import { withBi } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef } from '../api-types'
import { createPanelDefs } from '../services/panel-definitions'
import { ROLE_FORM } from '../../../constants/roles'
import { BillingPanelReferrer } from '../../../constants/premium'
import PANELS from './consts/panel-names'
import * as _ from 'lodash'

export default class ManagePanelsApi {
  private biLogger: any
  private boundEditorSDK: any
  private editorSDK: any
  private coreApi: CoreApi

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, { biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.editorSDK = editorSDK
  }

  public closePanel(panelToken) {
    return this.editorSDK.editor.closePanel(panelToken)
  }

  public openHelpPanel(helpId) {
    return this.boundEditorSDK.editor.openHelpPanel({ helpId })
  }

  public openAddFormPanel(panelDefinitions, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(panelDefinitions.addFormPanel())
  }

  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL })
  public async openFieldPanel(componentRef: ComponentRef, _biData = {}) {
    const [msid, { config }] = await this._fetchConfigAndMsid(componentRef)
    const mode = await this.boundEditorSDK.info.getEditorMode()

    return this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid).fieldSettingsPanel(componentRef, { config, componentRef }, mode)
    )
  }

  public async openSettingsPanel(childRef: ComponentRef) {
    const componentRef = await this.coreApi.findComponentByRole(childRef, ROLE_FORM)
    const [msid, { config }] = await this._fetchConfigAndMsid(componentRef)

    return this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid).settingsPanel(componentRef, {
        config,
        componentRef,
      })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.addFieldPanel.OPEN_PANEL })
  public async openAddFieldPanel(componentRef, panelToken, _biData = {}) {
    const formContainerRef = await this.coreApi.findComponentByRole(componentRef, ROLE_FORM)
    const [msid, { config }] = await this._fetchConfigAndMsid(formContainerRef)

    return this.editorSDK.editor.updatePanelOptions(
      panelToken,
      createPanelDefs(msid).addFieldPanel(formContainerRef, {
        config,
        componentRef: formContainerRef,
      })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.ACTION_CLICK })
  public closeUpgradeAlertPanel(token, _biData = {}) {
    return this.closePanel(token)
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.ACTION_CLICK })
  public upgradeUpgradeAlertPanel(componentRef: ComponentRef, referrer: BillingPanelReferrer) {
    return this.openPremiumBillingPanel(componentRef, { referrer })
  }

  public async openPremiumBillingPanel(
    componentRef: ComponentRef,
    { referrer, alertType }: { referrer?: BillingPanelReferrer; alertType?: string }
  ) {
    if (alertType) {
      const esi = await this.coreApi.getEditorSessionId()
      return this._openUpgradeAlertPanel(componentRef, alertType, {
        startBi: {
          form_comp_id: componentRef.id,
          esi,
          origin: referrer,
        },
      })
    }
    return this.boundEditorSDK.editor.openBillingPage({
      premiumIntent: this.editorSDK.editor.PremiumIntent.Neutral,
      referrer,
    })
  }

  public openSiteMembersSettingsPanel() {
    return this.editorSDK.editor.openPanel(
      PANELS.SITE_MEMBERS_SETTINGS_PANEL,
      { panelName: PANELS.SITE_MEMBERS_SETTINGS_PANEL },
      true
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD })
  public openBlockedFieldAlert(componentRef: ComponentRef, { referrer, alertType }, _biData = {}) {
    return this.openPremiumBillingPanel(componentRef, { referrer, alertType })
  }

  public async openComponentPanel(componentRef, panelName, panelDefinitions, onOpen) {
    const { role, controllerRef, config } = await this.coreApi.getComponentConnection(componentRef)
    const formComponentRef =
      role == ROLE_FORM
        ? componentRef
        : await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    const isSiteSaved = await this.boundEditorSDK.info.isSiteSaved()
    if (!isSiteSaved) {
      await this.boundEditorSDK.editor.save()
      const emailId = await this.coreApi.getOwnerEmailId()

      await this.coreApi.setComponentConnection(formComponentRef, { emailId })
      config.emailId = emailId
    }
    const mode = await this.boundEditorSDK.info.getEditorMode()
    this.boundEditorSDK.editor.openComponentPanel(
      panelDefinitions[panelName](componentRef, { config, componentRef }, mode)
    )
    onOpen(_.merge({ formId: formComponentRef.id }, config))
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.OPEN_PANEL })
  private async _openUpgradeAlertPanel(
    componentRef: ComponentRef,
    alertType: string,
    _biData = {}
  ) {
    const [msid, { config }] = await this._fetchConfigAndMsid(componentRef)

    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid).upgradeAlertPanel(componentRef, {
        config,
        componentRef,
        alertType,
      })
    )
  }

  private _fetchConfigAndMsid(componentRef: ComponentRef): Promise<[string, { config }]> {
    return Promise.all([
      this.coreApi.getMetaSiteId(),
      this.coreApi.getComponentConnection(componentRef),
    ])
  }
}
