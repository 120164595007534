import translations from '../services/translations'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from './manifest-commons'
import * as _ from 'lodash'

export const createBoxManifest = withPremium => {
  const manifestWithPremuim = {
    showFocusBox: true,
    displayName: translations.t('formBuilder'),
    behavior: { duplicatable: false },
    gfpp: {
      desktop: {
        mainAction1: {
          actionId: GFPP_IDS.MANAGE_FEILDS,
          label: translations.t('manageFields.gfppTitle'),
        },
        mainAction2: GFPP.REMOVE,
        iconButtons: {
          [GFPP_ACTIONS.SETTINGS]: {
            actionId: GFPP_IDS.FORM_SETTINGS,
          },
          [GFPP_ACTIONS.LAYOUT]: {
            actionId: GFPP_IDS.FORM_LAYOUT,
          },
          [GFPP_ACTIONS.DESIGN]: {
            actionId: GFPP_IDS.FORM_STYLE,
          },
          [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.UPGRADE]: {
            actionId: GFPP_IDS.PREMIUM,
          },
        },
        helpId: GFPP.HELP_ID.FORM_CONTAINER,
      },
      mobile: {
        /* use default configurations */
        helpId: GFPP.HELP_ID.FORM_CONTAINER_MOBILE,
      },
    },
  }

  return withPremium
    ? manifestWithPremuim
    : _.omit(manifestWithPremuim, ['gfpp.desktop.iconButtons.upgrade'])
}
