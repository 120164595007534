export interface FormField {
  question: string
  componentRef: ComponentRef
  crmLabel: string
  required: boolean
  crmType: string
  crmTag: string
  fieldType: string
  customFieldId?: string
  collectionFieldKey?: string
  collectionFieldType?: string
  checked?: boolean
  role: string
  label: string
  placeholder: string
  showLabel: boolean
  buttonLabel: string
}

export interface FormFieldDefinition {
  fieldId: string
  fieldName: string
  fieldCollectionType?: string
  fieldCollectionKey?: string
}

export type ComponentRef = {
  id: string
  type: string
}

export type ComponentConnection = {
  role: string
  config: any
  controllerRef: ComponentRef
  isPrimary: boolean
}

export type ColorPalette = {
  colorsPalette: Array<Array<string>>
  colors: object
}

export enum CriticalComponentTypes {
  SUBMIT_BUTTON = 'submitButton',
  HIDDEN_MESSAGE = 'hiddenMessage',
}
