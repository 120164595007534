import { undoable, withBi } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef } from '../api-types'
import CoreApi from '../core-api'
import {
  SuccessActionTypes,
  VISIBLE_LINK_PANEL_SECTIONS,
} from '../../../constants/success-settings'
import { ROLE_MESSAGE, ROLE_SUBMIT_BUTTON, ROLE_FORM } from '../../../constants/roles'
import {
  createHiddenMessage,
  getExtraMessageText,
  createSubmitButton,
} from '../services/form-service'
import { LinkTypes } from '../../../panels/settings-panel/constants/link-types'
import { MESSAGE_EXTRA_HEIGHT, SUBMIT, HIDDEN_MESSAGE } from './consts/heights'
import { innerText } from '../../../utils/utils'
import * as _ from 'lodash'

export default class SettingsApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: any
  private editorSDK: any

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, remoteApi, { biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.editorSDK = editorSDK
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.CREATE_SUBMISSIONS_TABLE,
    endEvid: EVENTS.PANELS.settingsPanel.SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY,
  })
  public async createCollection(componentRef: ComponentRef, _biData = {}): Promise<string> {
    return this.coreApi.createCollection(componentRef)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setComponentConnection(connectToRef: ComponentRef, connectionConfig, _biData = {}) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setLabels(connectToRef: ComponentRef, labels: string[], _biData = {}) {
    const { controllerRef, role, config } = await this.coreApi.getComponentConnection(connectToRef)
    config.labels = labels

    return this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig: config,
      isPrimary: true,
    })
  }

  @undoable()
  public async setSuccessActionType(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes
  ) {
    await this.coreApi.setComponentConnection(connectToRef, { successActionType })
    if (successActionType === SuccessActionTypes.SHOW_MESSAGE) {
      await this._addHiddenMessage(connectToRef)
    } else {
      this.removeSuccessMessage(connectToRef)
    }
  }

  @undoable()
  public addHiddenMessage(componentRef: ComponentRef) {
    return this._addHiddenMessage(componentRef)
  }

  public async removeSuccessMessage(componentRef: ComponentRef) {
    const get = async () => {
      const messageRef = await this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE)
      const messageLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: messageRef,
      })
      const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef })
      return { messageRef, messageLayout, boxLayout }
    }
    const update = async (boxLayout, messageLayout) => {
      await this.boundEditorSDK.components.layout.update({
        componentRef,
        layout: { height: boxLayout.height - (messageLayout.height + MESSAGE_EXTRA_HEIGHT) },
      })
    }

    const { messageRef, messageLayout, boxLayout } = await get()
    await update(boxLayout, messageLayout)
    return this.coreApi.removeComponentRef(messageRef)
  }

  @undoable()
  public async handleSuccessLinkPanel(componentRef: ComponentRef) {
    const {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)
    const linkObject = await this.boundEditorSDK.editor.openLinkPanel({
      value: successLinkValue,
      visibleSections: VISIBLE_LINK_PANEL_SECTIONS,
    })
    await this.setComponentConnection(componentRef, { successLinkValue: linkObject })
    const successLinkText = await this.boundEditorSDK.editor.utils.getLinkAsString({
      link: linkObject,
    })
    const successLinkType = await this._getLinkType(linkObject)
    const successLinkSubType = this._getLinkSubType(successLinkText, successLinkType, linkObject)
    return { successLinkText, successLinkType, successLinkSubType }
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setEmail(componentRef, emailId, email, _biData = {}) {
    const { id } = await this.remoteApi.insertEmail(email)
    return this.setComponentConnection(componentRef, { [emailId]: id })
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED,
    endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED,
  })
  public async updateSuccessMessage(componentRef: ComponentRef, newSuccessMessage, _biData = {}) {
    const get = async () => {
      const messageRef = await this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE)
      const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })
      return { messageRef, data }
    }
    const update = (messageRef, data) =>
      this.boundEditorSDK.components.data.update({
        componentRef: messageRef,
        data: getExtraMessageText(data, newSuccessMessage),
      })

    const { messageRef, data } = await get()
    return update(messageRef, data)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.SECONDS_TO_RESET_UPDATED })
  public setComponentConnectionResetUpdated(
    connectToRef: ComponentRef,
    connectionConfig,
    _biData = {}
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getTags() {
    return this.remoteApi.getTags()
  }

  @undoable()
  public async addSubmitButton(componentRef: ComponentRef) {
    const get = async () => {
      const {
        controllerRef,
        config: { theme },
      } = await this.coreApi.getComponentConnection(componentRef)
      const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef })
      return { controllerRef, theme, boxLayout }
    }

    const update = async (theme, controllerRef, boxLayout) => {
      await this.boundEditorSDK.components.layout.update({
        componentRef,
        layout: { height: boxLayout.height + SUBMIT.FORM_EXTRA_HEIGHT },
      })
      await this.coreApi.addComponentAndConnect(
        createSubmitButton({ y: boxLayout.height - SUBMIT.BUTTON_HEIGHT }, theme),
        controllerRef,
        componentRef
      )
    }

    const updateMessageLocation = async () => {
      const messages = await this.coreApi.layout.getChildrenLayouts(componentRef, ROLE_MESSAGE)
      const message: any = messages[0]
      if (!message) {
        return null
      }
      return this.boundEditorSDK.components.layout.update({
        componentRef: message.componentRef,
        layout: { y: message.y + SUBMIT.MESSAGE },
      })
    }

    const { controllerRef, theme, boxLayout } = await get()
    if (!boxLayout) {
      return null
    }
    await update(theme, controllerRef, boxLayout)
    return updateMessageLocation()
  }

  public async getSuccessMessage(componentRef: ComponentRef) {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE)
    if (!messageRef) {
      return ''
    }
    const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })
    return { successMessage: innerText(data.text) }
  }

  public async getEmails(componentRef) {
    const {
      config: { emailId, secondEmailId },
    } = await this.coreApi.getComponentConnection(componentRef)
    return Promise.all([
      this.remoteApi.getEmailById(emailId),
      this.remoteApi.getEmailById(secondEmailId),
    ])
  }

  public async getCrucialElements(componentRef: ComponentRef) {
    const [
      submitButtonRef,
      messageRef,
      {
        config: { successActionType },
      },
    ] = await Promise.all([
      this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE),
      this.coreApi.getComponentConnection(componentRef),
    ])
    return {
      isSubmitButtonExists: !!submitButtonRef,
      isHiddenMessageExists: successActionType === SuccessActionTypes.LINK || !!messageRef,
    }
  }

  public async getSuccessLinkText(componentRef) {
    const {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)
    return await this.boundEditorSDK.editor.utils.getLinkAsString({ link: successLinkValue })
  }

  public async getOtherFormsNames(componentRef: ComponentRef): Promise<string[]> {
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const controllers = _.map(
      await this.boundEditorSDK.controllers.listAllControllers(),
      ({ controllerRef }) => controllerRef
    )
    return await Promise.all(
      _.map(_.pullAllBy(controllers, [controllerRef], 'id'), async formControllerRef => {
        const formRef = await this.coreApi.findConnectedComponent(formControllerRef, ROLE_FORM)
        if (!formRef) {
          return ''
        }
        const {
          config: { formName },
        } = await this.coreApi.getComponentConnection(formRef)
        return formName
      })
    )
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async updateFormName(
    connectToRef: ComponentRef,
    { formName }: { formName: string },
    _biData = {}
  ) {
    await this.coreApi.setComponentConnection(connectToRef, { formName })
    const {
      config: { formLabelId, labels },
    } = await this.coreApi.getComponentConnection(connectToRef)
    if (formLabelId && _.includes(labels, formLabelId)) {
      await this.remoteApi.updateTag(formLabelId, formName)
    }
  }

  private async _addHiddenMessage(componentRef: ComponentRef) {
    const get = async () => {
      const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
      const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef })
      return { boxLayout, controllerRef }
    }

    const update = async boxLayout => {
      await this.boundEditorSDK.components.layout.update({
        componentRef,
        layout: { height: boxLayout.height + HIDDEN_MESSAGE.FORM_EXTRA_HEIGHT },
      })
      return this.coreApi.addComponentAndConnect(
        createHiddenMessage({ y: boxLayout.height - HIDDEN_MESSAGE.HIDDEN_MESSAGE_HEIGHT }),
        controllerRef,
        componentRef
      )
    }

    const { boxLayout, controllerRef } = await get()
    return update(boxLayout)
  }

  private async _getLinkType(linkObject) {
    if (!_.get(linkObject, 'pageId')) {
      return LinkTypes.NONE
    }

    const linkedPageRef = { type: 'DESKTOP', id: linkObject.pageId.substring(1) }
    const linkData = await this.boundEditorSDK.components.data.get({
      componentRef: linkedPageRef,
    })
    return _.get(linkData, 'isPopup') ? LinkTypes.LIGHTBOX : LinkTypes.PAGE
  }

  private _getLinkSubType(successLinkText, successLinkType, linkObject) {
    switch (successLinkType) {
      case LinkTypes.PAGE:
        return successLinkText
      case LinkTypes.LIGHTBOX:
        return linkObject.pageId
      default:
        return null
    }
  }
}
