export const GFPP = {
  HELP_ID: {
    FORM_CONTAINER: 'bf285f63-1b78-4de5-b99e-f22b5ee1b33c',
    FORM_CONTAINER_MOBILE: '1e7e55ab-7637-4292-9469-28a501df6474',
    FIELD: '79200180-9916-4cab-a066-7211459c0709',
    FIELD_MOBILE: '6ddb0b8b-a4af-471f-a63e-ef3cb1084892',
  },
  KEEP_DEFAULT: 'DEFAULT',
  REMOVE: 'HIDE',
}

export const GFPP_ACTIONS = {
  SETTINGS: 'settings',
  LAYOUT: 'layout',
  DESIGN: 'design',
  ANIMATION: 'animation',
  UPGRADE: 'upgrade',
  CONNECT: 'connect',
  LINK: 'link',
}

export enum GFPP_IDS {
  FORM_SETTINGS = 'form-settings',
  MANAGE_FEILDS = 'manage-fields',
  FIELD_SETTINGS = 'field-settings',
  FORM_STYLE = 'form-style',
  FORM_LAYOUT = 'form-layout',
  CHANGE_BUTTON_CHANGED = 'button-change-text',
  PREMIUM = 'premium',
}
