import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import { makeGeneralSingleCheckbox } from '../../general-fields/definitions/general-single-checkbox'
import translations from '../../../../services/translations'
import { Field } from '../../field-types-data'

export const makeCheckboxAgreeTerms = (): Field => {
  const t = translations.t.bind(translations)

  return _.merge(makeGeneralSingleCheckbox(), {
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS,
      connectionConfig: {
        crmLabel: t(`fieldTypes.${FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS}`),
      },
      data: {
        value: 'unchecked',
        label: t(`fieldTypes.${FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS}.label`),
      },
    },
  })
}
